import { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import {
  useMask,
  useGLTF,
  useAnimations,
  Float,
  Instance,
  Instances,
  CameraControls,
  DeviceOrientationControls,
  OrbitControls,
  PresentationControls,
} from '@react-three/drei';
import {
  Lightformer,
  Environment,
  RandomizedLight,
  AccumulativeShadows,
  MeshTransmissionMaterial,
} from '@react-three/drei';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../helpers/windowWidth';

const ControlComp = ({ touchState }) => {
  const cameraRef = useRef();
  const [polarState, setPolarState] = useState('down');

  useFrame(() => {
    if (cameraRef.current && !touchState) {
      cameraRef.current.rotateTo.azimuthAngle =
        cameraRef.current.azimuthAngle += 0.002;
    }

    if (cameraRef.current && polarState === 'down' && !touchState) {
      cameraRef.current.rotateTo.polarAngle =
        cameraRef.current.polarAngle -= 0.002;
    }

    if (cameraRef.current && polarState === 'up' && !touchState) {
      cameraRef.current.rotateTo.polarAngle =
        cameraRef.current.polarAngle += 0.002;
    }

    if (cameraRef.current && cameraRef.current.polarAngle > 3) {
      setPolarState('down');
    }

    if (cameraRef.current && cameraRef.current.polarAngle < 0.3) {
      setPolarState('up');
    }
  });

  return (
    <>
      <CameraControls
        truckSpeed={0}
        dollySpeed={0}
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2}
        ref={cameraRef}

        //   lockPointer={e => {
        //     console.log(e);
        //   }}
      />
    </>
  );
};

export default function App({
  spheres,
  scale,
  workDetail,
  model,
  position,
  ready,
}) {
  const [touchState, setTouchState] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  return (
    <Canvas
      shadows
      camera={{ position: [30, 0, -3], fov: 35, near: 1, far: 50 }}
      onCreated={state => {
        state.gl.setClearColor(0x000000, 0);
        ready();
      }}
      
      onMouseDown={() => setTouchState(true)}
      onMouseUp={() => setTouchState(false)}
      onWheel={e => {
        if (workDetail && width > 940) {
          if (e.deltaY > 0) {
            window.scrollTo(0, e.target.height);
          } else {
            window.scrollTo(0, 0);
          }
        }
      }}
      eventPrefix="offset"
    >
      {/* <color attach="background" args={['red']} /> */}
      {/** Glass aquarium */}

      <Aquarium position={[0, 0.25, 0]}>
        <Float rotationIntensity={2} floatIntensity={10} speed={2}>
          {workDetail ? (
            <WorkModel
              position={position}
              rotation={[0, Math.PI, 0]}
              scale={scale}
              model={model}
            />
          ) : (
            <Turtle
              position={[0, -0.5, -1]}
              rotation={[0, Math.PI, 0]}
              scale={scale}
              
            />
          )}
        </Float>
        <Instances renderOrder={-1000}>
          <sphereGeometry args={[1, 64, 64]} />
          <meshBasicMaterial depthTest={false} />
          {spheres.map(([scale, color, speed, position], index) => (
            <Sphere
              key={index}
              scale={scale}
              color={color}
              speed={speed}
              position={position}
            />
          ))}
        </Instances>
      </Aquarium>
      {/** Soft shadows */}
      {/* <AccumulativeShadows
        temporal
        frames={100}
        color="red"
        colorBlend={2}
        opacity={0.7}
        scale={60}
        position={[0, -5, 0]}
      >
        <RandomizedLight
          amount={8}
          radius={15}
          ambient={0.5}
          intensity={1}
          position={[-5, 10, -5]}
          size={20}
        />
      </AccumulativeShadows> */}
      {/** Custom environment map */}
      <Environment resolution={1024}>
        <group rotation={[-Math.PI / 3, 0, 0]}>
          <Lightformer
            intensity={4}
            rotation-x={Math.PI / 2}
            position={[0, 5, -9]}
            scale={[10, 10, 1]}
          />
          {[2, 0, 2, 0, 2, 0, 2, 0].map((x, i) => (
            <Lightformer
              key={i}
              form="circle"
              intensity={4}
              rotation={[Math.PI / 2, 0, 0]}
              position={[x, 4, i * 4]}
              scale={[4, 1, 1]}
            />
          ))}
          <Lightformer
            intensity={2}
            rotation-y={Math.PI / 2}
            position={[-5, 1, -1]}
            scale={[50, 2, 1]}
          />
          <Lightformer
            intensity={2}
            rotation-y={-Math.PI / 2}
            position={[10, 1, 0]}
            scale={[50, 2, 1]}
          />
        </group>
      </Environment>
      <ControlComp touchState={touchState} />
    </Canvas>
  );
}

function Aquarium({ children, ...props }) {
  const ref = useRef();
  const { nodes } = useGLTF('/shapes-transformed.glb');
  const stencil = useMask(1, false);
  useLayoutEffect(() => {
    // Apply stencil to all contents
    ref.current.traverse(
      child => child.material && Object.assign(child.material, { ...stencil })
    );
  }, []);
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        scale={[0.61 * 6, 0.8 * 6, 1 * 6]}
        geometry={nodes.Cube.geometry}
      >
        <MeshTransmissionMaterial
          backside
          samples={4}
          thickness={3}
          chromaticAberration={0.025}
          anisotropy={0.1}
          distortion={0.1}
          distortionScale={0.1}
          temporalDistortion={0.2}
          iridescence={1}
          iridescenceIOR={1}
          iridescenceThicknessRange={[0, 1400]}
        />
      </mesh>
      <group ref={ref}>{children}</group>
    </group>
  );
}

function Sphere({ position, scale = 1, speed = 0.1, color = 'white' }) {
  return (
    <Float rotationIntensity={40} floatIntensity={20} speed={speed / 2}>
      <Instance position={position} scale={scale} color={color} />
    </Float>
  );
}

/*
Author: DigitalLife3D (https://sketchfab.com/DigitalLife3D)
License: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
Source: https://sketchfab.com/3d-models/model-52a-kemps-ridley-sea-turtle-no-id-7aba937dfbce480fb3aca47be3a9740b
Title: Model 52A - Kemps Ridley Sea Turtle (no ID)
*/
function Turtle(props) {
  const { scene, animations } = useGLTF(
    '/model_52a_-_kemps_ridley_sea_turtle_no_id-transformed.glb'
  );
  const { actions, mixer } = useAnimations(animations, scene);
  useEffect(() => {
    mixer.timeScale = 0.5;
    actions['Swim Cycle'].play();
  }, []);
  useFrame(
    state => (scene.rotation.z = Math.sin(state.clock.elapsedTime / 4) / 2)
  );
  return <primitive object={scene} {...props} />;
}

function WorkModel(props) {
  const { scene } = useGLTF(props.model);
  //   useEffect(() => {
  //     mixer.timeScale = 0.5;
  //     actions['Swim Cycle'].play();
  //   }, []);
  //   useFrame(
  //     state => (scene.rotation.z = Math.sin(state.clock.elapsedTime / 4) / 2)
  //   );
  return <primitive object={scene} {...props} />;
}
