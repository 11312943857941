import { ContactSvg, GithubIcon, InstagramIcon } from '../../assets/icon';
import style from './contact.module.scss';
import { Header, MainHelmet } from '../../components';
import useWindowDimensions from '../../helpers/windowWidth';

const Contact = () => {
  const { width } = useWindowDimensions();
  return (
    <div className={style.contact}>
      <MainHelmet
        title={'Contact'}
        slug={'/contact'}
        keywords={
          'alter-text, Studio, frontend,backend, development, ui, design'
        }
        description={
          'Alter-Text is an independent web studio founded in 2021. We offer creative and systemic solutions to create digital products. We love using new generation technologies.'
        }
      />
      <Header white />
      <div className={style.svgWrapper}>
        <ContactSvg mobile={width < 940} />
      </div>
      <div className={style.info}>
        <a
          className={style.mail}
          href="mailto:info@altertext.studio"
          target="_blank"
        >
          info@altertext.studio
        </a>
        <div className={style.social}>
          <a
            href="https://www.instagram.com/altertextstudio/"
            target={'_blank'}
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://github.com/Altertext-Studio"
            target={'_blank'}
            rel="noreferrer"
          >
            <GithubIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
