import { WorkData } from '../../data/WorkData';
import style from './workdetail.module.scss';
import { useLocation } from 'react-router-dom';
import {
  Aquarium,
  Header,
  Loading,
  CanRotate,
  MainHelmet,
} from '../../components';
import { useState, useEffect, useRef } from 'react';
import { LiveIcon } from '../../assets/icon';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const WorkDetail = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[2];
  const currentWork = WorkData.filter(i => i.slug === currentLocation)[0];
  const [isLoading, setLoading] = useState(true);
  const [isModel, setModel] = useState(true);
  const [canRotate, setRotate] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        localStorage.setItem('canRotate', 'true');
        setRotate(false);
      }, 3000);
    }
  }, [isLoading]);

  useEffect(() => {
    const rotateState = localStorage.getItem('canRotate');

    if (rotateState) {
      setRotate(false);
    } else {
      setRotate(true);
    }
  }, []);
  useEffect(() => {
    axios
      .get(`${currentWork.model}`)
      .then(res => {
        console.log(res.data);
        setModel(false);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {!isLoading && canRotate ? (
        <CanRotate
          click={() => {
            localStorage.setItem('canRotate', 'true');
            setRotate(false);
          }}
        />
      ) : null}
      <div
        style={{
          backgroundColor: currentWork.color,
        }}
        className={style.workDetail}
      >
        <MainHelmet
          title={`${currentWork.title}`}
          slug={currentWork.slug}
          keywords={`${currentWork.services.map(i => i)} ${currentWork.title} ${
            currentWork.subTitle
          } ${currentWork.brand} ${currentWork.slug} ${currentWork.liveUrl}`}
          description={currentWork.subTitle}
        />

        <Header />
        <div
          style={{
            backgroundColor: currentWork.color,
          }}
          className={style.head}
        >
          {<currentWork.svg />}
          <div className={style.scene}>
            <Aquarium
              scale={currentWork.modelScale}
              model={currentWork.model}
              spheres={[[1, 'transparent', 0.05, [-4, -1, -1]]]}
              workDetail
              position={currentWork.modelPosition}
              ready={() => setLoading(false)}
            />
          </div>
          <div className={style.info}>
            <div className={style.column}>
              <h5>Services</h5>
              <ul>
                {currentWork.services.map(item => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
            <div className={style.column}>
              <h5>Date</h5>
              <ul>
                <li>{currentWork.date}</li>
              </ul>
            </div>
            <div className={style.column}>
              <h5>Brand</h5>
              <ul>
                <li>{currentWork.brand}</li>
              </ul>
            </div>
            {currentWork.collaborator ? (
              <div className={style.column}>
                <h5>Collaborator</h5>
                <ul>
                  {currentWork.collaborator.map(item => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
            ) : null}
            <div className={style.live}>
              <a target={'_blank'} href={currentWork.liveUrl}>
                SEE LIVE <LiveIcon />
              </a>
            </div>
          </div>
        </div>

        {isModel ? null : (
          <div id="work" className={style.workImages}>
            {currentWork.workImages.map((item, index) => {
              return (
                <>
                  <img src={item} alt={`${currentWork.title}-work`} />
                  {currentWork.videos && index === 0 ? (
                    <video autoPlay muted playsinline loop>
                      <source src={currentWork.videos[1]} type="video/mp4" />
                    </video>
                  ) : null}
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default WorkDetail;
