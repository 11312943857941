import { useEffect, useState } from 'react';
import style from './loading.module.scss';

const Loading = () => {
  const [dot, setDot] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (dot === 3) {
        setDot(0);
      } else {
        setDot(dot + 1);
      }
    }, 500);
  }, [dot]);

  return (
    <div className={style.loading}>
      <h1>Loading {[...new Array(dot)].map(i => '.')}</h1>
    </div>
  );
};

export default Loading;
