import {
  AremasSvg,
  BayetavSvg,
  BorveySvg,
  BubbleSvg,
  DanteSvg,
  DesteSvg,
  FratelliSvg,
  GolfSvg,
  KubraSvg,
  MCSSvg,
  MoveOnSvg,
  MuralSvg,
  OusSvg,
  PeanutSvg,
  PremeyoumSvg,
  SSSvg,
  SafeKidsSvg,
  ToolsySvg,
  VialandSvg,
  YumacareSvg,
} from "../assets/icon";

// Toolsy
import ToolsyCover from "../assets/img/works/toolsy/toolsy-cover.png";

import Toolsy1 from "../assets/img/works/toolsy/toolsy-1.png";
import Toolsy2 from "../assets/img/works/toolsy/toolsy-2.png";
import Toolsy3 from "../assets/img/works/toolsy/toolsy-3.png";
import Toolsy4 from "../assets/img/works/toolsy/toolsy-4.png";
import Toolsy5 from "../assets/img/works/toolsy/toolsy-5.png";
import Toolsy6 from "../assets/img/works/toolsy/toolsy-6.png";
import Toolsy7 from "../assets/img/works/toolsy/toolsy-7.png";
import Toolsy8 from "../assets/img/works/toolsy/toolsy-8.png";
import Toolsy9 from "../assets/img/works/toolsy/toolsy-9.png";
import Toolsy10 from "../assets/img/works/toolsy/toolsy-10.png";
import Toolsy11 from "../assets/img/works/toolsy/toolsy-11.png";
import Toolsy12 from "../assets/img/works/toolsy/toolsy-12.png";

// Golf Always
import GolfAlwaysCover from "../assets/img/works/golf-always/golf-always-cover.png";
import GolfAlways1 from "../assets/img/works/golf-always/golf-always-1.png";
import GolfAlways2 from "../assets/img/works/golf-always/golf-always-2.png";
import GolfAlways3 from "../assets/img/works/golf-always/golf-always-3.png";
import GolfAlways4 from "../assets/img/works/golf-always/golf-always-4.png";
import GolfAlways5 from "../assets/img/works/golf-always/golf-always-5.png";
import GolfAlwaysHoverCover from "../assets/img/works/golf-always/golf-always-hover-cover.png";

// OusStudio
import OusStudiCover from "../assets/img/works/ous-studio/ous-studio-cover.png";

import OusStudio1 from "../assets/img/works/ous-studio/ous-studio-1.jpg";
import OusStudio2 from "../assets/img/works/ous-studio/ous-studio-2.jpg";
import OusStudio3 from "../assets/img/works/ous-studio/ous-studio-3.jpg";
import OusStudio4 from "../assets/img/works/ous-studio/ous-studio-4.jpg";
import OusStudio5 from "../assets/img/works/ous-studio/ous-studio-5.jpg";
import OusStudio6 from "../assets/img/works/ous-studio/ous-studio-6.jpg";
import OusStudio7 from "../assets/img/works/ous-studio/ous-studio-7.jpg";
import OusStudio8 from "../assets/img/works/ous-studio/ous-studio-8.jpg";
import OusStudio9 from "../assets/img/works/ous-studio/ous-studio-9.jpg";

// Premeyoum
import PremeyoumCover from "../assets/img/works/premeyoum/premeyoum-cover.png";

import Premeyoum1 from "../assets/img/works/premeyoum/premeyoum-1.png";
import Premeyoum2 from "../assets/img/works/premeyoum/premeyoum-2.png";
import Premeyoum3 from "../assets/img/works/premeyoum/premeyoum-3.png";
import Premeyoum4 from "../assets/img/works/premeyoum/premeyoum-4.png";
import Premeyoum5 from "../assets/img/works/premeyoum/premeyoum-5.png";
import Premeyoum6 from "../assets/img/works/premeyoum/premeyoum-6.png";
import Premeyoum7 from "../assets/img/works/premeyoum/premeyoum-7.png";
import Premeyoum8 from "../assets/img/works/premeyoum/premeyoum-8.png";
import Premeyoum9 from "../assets/img/works/premeyoum/premeyoum-9.png";
import PremeyoumHoverCover from "../assets/img/works/premeyoum/premeyoum-hover-cover.png";

// KübraÇetin
import KubraCetinCover from "../assets/img/works/kubra-cetin/kubra-cetin-cover.png";

import KubraCetin1 from "../assets/img/works/kubra-cetin/kubra-cetin-1.png";
import KubraCetin2 from "../assets/img/works/kubra-cetin/kubra-cetin-2.png";
import KubraCetin3 from "../assets/img/works/kubra-cetin/kubra-cetin-3.png";
import KubraCetin4 from "../assets/img/works/kubra-cetin/kubra-cetin-4.png";
import KubraCetin5 from "../assets/img/works/kubra-cetin/kubra-cetin-5.png";

// SafeKids
import SafeKidsCover from "../assets/img/works/safe-kids/safe-kids-cover.png";
import SafeKids1 from "../assets/img/works/safe-kids/safe-kids-1.png";
import SafeKids2 from "../assets/img/works/safe-kids/safe-kids-2.png";
import SafeKids3 from "../assets/img/works/safe-kids/safe-kids-3.png";
import SafeKids4 from "../assets/img/works/safe-kids/safe-kids-4.png";
import SafeKids5 from "../assets/img/works/safe-kids/safe-kids-5.png";
import SafeKids6 from "../assets/img/works/safe-kids/safe-kids-6.png";
import SafeKids7 from "../assets/img/works/safe-kids/safe-kids-7.png";

// Aremas
import AremasCover from "../assets/img/works/aremas/aremas-cover.png";

import Aremas1 from "../assets/img/works/aremas/aremas-1.png";
import Aremas2 from "../assets/img/works/aremas/aremas-2.png";
import Aremas3 from "../assets/img/works/aremas/aremas-3.png";
import Aremas4 from "../assets/img/works/aremas/aremas-4.png";
import Aremas5 from "../assets/img/works/aremas/aremas-5.png";
import Aremas6 from "../assets/img/works/aremas/aremas-6.png";
import AremasHoverCover from "../assets/img/works/aremas/aremas-hover-cover.png";

// MoveOn
import MoveOnCover from "../assets/img/works/move-on/move-on-cover.png";
import MoveOn1 from "../assets/img/works/move-on/move-on-1.png";
import MoveOn2 from "../assets/img/works/move-on/move-on-2.png";
import MoveOn3 from "../assets/img/works/move-on/move-on-3.png";
import MoveOn4 from "../assets/img/works/move-on/move-on-4.png";
import MoveOnHoverCover from "../assets/img/works/move-on/move-on-hover-cover.png";

// KibeleYarman
import KibeleYarmanCover from "../assets/img/works/kibele-yarman/kibele-yarman-cover.png";
import KibeleYarman1 from "../assets/img/works/kibele-yarman/kibele-yarman-1.png";
import KibeleYarman2 from "../assets/img/works/kibele-yarman/kibele-yarman-2.png";

// Worldofplasy
import WorldofPlastCover from "../assets/img/works/worldofplast/worldofplast-cover.png";
import WorldofPlast1 from "../assets/img/works/worldofplast/worldofplast-1.png";
import WorldofPlast2 from "../assets/img/works/worldofplast/worldofplast-2.png";
import WorldofPlastHoverCover from "../assets/img/works/worldofplast/worldofplast-hover-cover.png";

//Peanut Games
import PeanutGamesCover from "../assets/img/works/peanut-games/peanut-games-cover.png";

import PeanutGames1 from "../assets/img/works/peanut-games/peanut-games-1.png";
import PeanutGames2 from "../assets/img/works/peanut-games/peanut-games-2.png";
import PeanutGames3 from "../assets/img/works/peanut-games/peanut-games-3.png";
import PeanutGames4 from "../assets/img/works/peanut-games/peanut-games-4.png";
import PeanutGames5 from "../assets/img/works/peanut-games/peanut-games-5.png";
import PeanutGames6 from "../assets/img/works/peanut-games/peanut-games-6.png";
import PeanutGames7 from "../assets/img/works/peanut-games/peanut-games-7.png";
import PeanutGames8 from "../assets/img/works/peanut-games/peanut-games-8.png";
import PeanutGames9 from "../assets/img/works/peanut-games/peanut-games-9.png";

//Mural Istanbul
import MuralEastCover from "../assets/img/works/mural-east/mural-east-cover.png";

import MuralEast1 from "../assets/img/works/mural-east/mural-east-1.png";
import MuralEast2 from "../assets/img/works/mural-east/mural-east-2.png";
import MuralEast3 from "../assets/img/works/mural-east/mural-east-3.png";
import MuralEast4 from "../assets/img/works/mural-east/mural-east-4.png";
import MuralEast5 from "../assets/img/works/mural-east/mural-east-5.png";
import MuralEast6 from "../assets/img/works/mural-east/mural-east-6.png";
import MuralEast7 from "../assets/img/works/mural-east/mural-east-7.png";
import MuralEast8 from "../assets/img/works/mural-east/mural-east-8.png";
import MuralEast9 from "../assets/img/works/mural-east/mural-east-9.png";
import MuralEast10 from "../assets/img/works/mural-east/mural-east-10.png";
import MuralEast11 from "../assets/img/works/mural-east/mural-east-11.png";
import MuralEast12 from "../assets/img/works/mural-east/mural-east-12.png";
import MuralEast13 from "../assets/img/works/mural-east/mural-east-13.png";
import MuralHoverCover from "../assets/img/works/mural-east/mural-east-hover-cover.png";

//Bubble Bots
import BubbleBotsMainCover from "../assets/img/works/bubble-bots/bubble-bots-main-cover.png";

import BubbleBotsCover from "../assets/img/works/bubble-bots/bubble-bots-cover.png";
import BubbleBots1 from "../assets/img/works/bubble-bots/bubble-bots-1.png";
import BubbleBots2 from "../assets/img/works/bubble-bots/bubble-bots-2.png";
import BubbleBots3 from "../assets/img/works/bubble-bots/bubble-bots-3.png";
import BubbleBots4 from "../assets/img/works/bubble-bots/bubble-bots-4.png";
import BubbleBots5 from "../assets/img/works/bubble-bots/bubble-bots-5.png";
import BubbleBots6 from "../assets/img/works/bubble-bots/bubble-bots-6.png";
import BubbleBotsHoverCover from "../assets/img/works/bubble-bots/bubble-bots-hover-cover.png";

//DANTE
import Dante1 from "../assets/img/works/dante/dante-1.png";
import Dante2 from "../assets/img/works/dante/dante-2.png";
import Dante3 from "../assets/img/works/dante/dante-3.png";
import Dante4 from "../assets/img/works/dante/dante-4.png";
import Dante5 from "../assets/img/works/dante/dante-5.png";
import Dante6 from "../assets/img/works/dante/dante-6.png";
import Dante7 from "../assets/img/works/dante/dante-7.png";
import Dante8 from "../assets/img/works/dante/dante-8.png";
import Dante9 from "../assets/img/works/dante/dante-9.png";
import Dante10 from "../assets/img/works/dante/dante-10.png";
import DanteHoverCover from "../assets/img/works/dante/dante-hover-cover.png";

//VIALAND
import Vialand1 from "../assets/img/works/vialand/vialand-1.png";
import Vialand2 from "../assets/img/works/vialand/vialand-2.png";
import Vialand3 from "../assets/img/works/vialand/vialand-3.png";
import Vialand4 from "../assets/img/works/vialand/vialand-4.png";
import Vialand5 from "../assets/img/works/vialand/vialand-5.png";
import Vialand6 from "../assets/img/works/vialand/vialand-6.png";
import Vialand7 from "../assets/img/works/vialand/vialand-7.png";
import Vialand8 from "../assets/img/works/vialand/vialand-8.png";
import Vialand9 from "../assets/img/works/vialand/vialand-9.png";
import Vialand10 from "../assets/img/works/vialand/vialand-10.png";
import Vialand11 from "../assets/img/works/vialand/vialand-11.png";
import Vialand12 from "../assets/img/works/vialand/vialand-12.png";
import Vialand13 from "../assets/img/works/vialand/vialand-13.png";
import Vialand14 from "../assets/img/works/vialand/vialand-14.png";
import VialandHoverCover from "../assets/img/works/vialand/vialand-hover-cover.png";

//DESTE

import Deste1 from "../assets/img/works/deste/deste-1.png";
import Deste2 from "../assets/img/works/deste/deste-2.png";
import Deste3 from "../assets/img/works/deste/deste-3.png";
import Deste4 from "../assets/img/works/deste/deste-4.png";
import Deste5 from "../assets/img/works/deste/deste-5.png";
import Deste6 from "../assets/img/works/deste/deste-6.png";
import Deste7 from "../assets/img/works/deste/deste-7.png";
import Deste8 from "../assets/img/works/deste/deste-8.png";
import Deste9 from "../assets/img/works/deste/deste-9.png";
import Deste10 from "../assets/img/works/deste/deste-10.png";
import Deste11 from "../assets/img/works/deste/deste-11.png";
import DesteHoverCover from "../assets/img/works/deste/deste-hover-cover.png";

//FRATELLI DIAMANTI
import FratelliDiamanti1 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-1.png";
import FratelliDiamanti2 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-2.png";
import FratelliDiamanti3 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-3.png";
import FratelliDiamanti4 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-4.png";
import FratelliDiamanti5 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-5.png";
import FratelliDiamanti6 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-6.png";
import FratelliDiamanti7 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-7.png";
import FratelliDiamanti8 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-8.png";
import FratelliDiamanti9 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-9.png";
import FratelliDiamanti10 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-10.png";
import FratelliDiamanti11 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-11.png";
import FratelliDiamanti12 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-12.png";
import FratelliDiamanti13 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-13.png";
import FratelliDiamanti14 from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-14.png";
import FratelliDiamantiHoverCover from "../assets/img/works/fratelli-diamanti/fratelli-diamanti-hover-cover.png";

//MCS
import MCS1 from "../assets/img/works/mcs/mcs-1.png";
import MCS2 from "../assets/img/works/mcs/mcs-2.png";
import MCS3 from "../assets/img/works/mcs/mcs-3.png";
import MCS4 from "../assets/img/works/mcs/mcs-4.png";
import MCS5 from "../assets/img/works/mcs/mcs-5.png";
import MCS6 from "../assets/img/works/mcs/mcs-6.png";
import MCS7 from "../assets/img/works/mcs/mcs-7.png";
import MCS8 from "../assets/img/works/mcs/mcs-8.png";
import MCS9 from "../assets/img/works/mcs/mcs-9.png";
import MCS10 from "../assets/img/works/mcs/mcs-10.png";
import MCS11 from "../assets/img/works/mcs/mcs-11.png";
import MCS12 from "../assets/img/works/mcs/mcs-12.png";
import MCSHoverCover from "../assets/img/works/mcs/mcs-hover-cover.png";

//YUMACARE
import Yumacare1 from "../assets/img/works/yumacare/yumacare-1.png";
import Yumacare2 from "../assets/img/works/yumacare/yumacare-2.png";
import Yumacare3 from "../assets/img/works/yumacare/yumacare-3.png";
import Yumacare4 from "../assets/img/works/yumacare/yumacare-4.png";
import Yumacare5 from "../assets/img/works/yumacare/yumacare-5.png";
import Yumacare6 from "../assets/img/works/yumacare/yumacare-6.png";
import Yumacare7 from "../assets/img/works/yumacare/yumacare-7.png";
import Yumacare8 from "../assets/img/works/yumacare/yumacare-8.png";
import Yumacare9 from "../assets/img/works/yumacare/yumacare-9.png";
import Yumacare10 from "../assets/img/works/yumacare/yumacare-10.png";
import Yumacare11 from "../assets/img/works/yumacare/yumacare-11.png";
import Yumacare12 from "../assets/img/works/yumacare/yumacare-12.png";
import Yumacare13 from "../assets/img/works/yumacare/yumacare-13.png";
import Yumacare14 from "../assets/img/works/yumacare/yumacare-14.png";
import Yumacare15 from "../assets/img/works/yumacare/yumacare-15.png";
import Yumacare16 from "../assets/img/works/yumacare/yumacare-16.png";
import Yumacare17 from "../assets/img/works/yumacare/yumacare-17.png";
import Yumacare18 from "../assets/img/works/yumacare/yumacare-18.png";
import Yumacare19 from "../assets/img/works/yumacare/yumacare-19.png";
import YumacareHoverCover from "../assets/img/works/yumacare/yumacare-hover-cover.png";

//YUMACARE
import Bayetav1 from "../assets/img/works/bayetav/bayetav-1.png";
import Bayetav2 from "../assets/img/works/bayetav/bayetav-2.png";
import Bayetav3 from "../assets/img/works/bayetav/bayetav-3.png";
import Bayetav4 from "../assets/img/works/bayetav/bayetav-4.png";
import Bayetav5 from "../assets/img/works/bayetav/bayetav-5.png";
import Bayetav6 from "../assets/img/works/bayetav/bayetav-6.png";
import Bayetav7 from "../assets/img/works/bayetav/bayetav-7.png";
import Bayetav8 from "../assets/img/works/bayetav/bayetav-8.png";
import Bayetav9 from "../assets/img/works/bayetav/bayetav-9.png";
import Bayetav10 from "../assets/img/works/bayetav/bayetav-10.png";
import Bayetav11 from "../assets/img/works/bayetav/bayetav-11.png";
import Bayetav12 from "../assets/img/works/bayetav/bayetav-12.png";
import Bayetav13 from "../assets/img/works/bayetav/bayetav-13.png";
import Bayetav14 from "../assets/img/works/bayetav/bayetav-14.png";
import Bayetav15 from "../assets/img/works/bayetav/bayetav-15.png";
import BayetavHoverCover from "../assets/img/works/bayetav/bayetav-hover-cover.png";

//BORVEY
import Borvey1 from "../assets/img/works/borvey/borvey-1.png";
import Borvey2 from "../assets/img/works/borvey/borvey-2.png";
import Borvey3 from "../assets/img/works/borvey/borvey-3.png";
import Borvey4 from "../assets/img/works/borvey/borvey-4.png";
import Borvey5 from "../assets/img/works/borvey/borvey-5.png";
import Borvey6 from "../assets/img/works/borvey/borvey-6.png";
import Borvey7 from "../assets/img/works/borvey/borvey-7.png";
import Borvey8 from "../assets/img/works/borvey/borvey-8.png";
import Borvey9 from "../assets/img/works/borvey/borvey-9.png";
import Borvey10 from "../assets/img/works/borvey/borvey-10.png";
import Borvey11 from "../assets/img/works/borvey/borvey-11.png";
import BorveyHoverCover from "../assets/img/works/borvey/borvey-1.png";

export const WorkData = [
  {
    title: "borvey",
    slug: "borvey",
    color: "#F59A13",
    svg: BorveySvg,
    model: "/cargo.glb",
    modelScale: 0.8,
    hoverCover: Borvey2,
    modelPosition: [0, -1, -1],
    workImages: [
      Borvey1,
      Borvey2,
      Borvey3,
      Borvey4,
      Borvey5,
      Borvey6,
      Borvey7,
      Borvey8,
      Borvey9,
      Borvey10,
      Borvey11,
    ],
    subTitle: `Taşıma hizmeti almak isteyenlerle nakliye sektörü oyuncularını bir araya getiren paylaşım platformu...`,
    services: [
      "Project Management",
      "Frontend Development",
      "Backend Development",
      "Admin Panel Development",
      "UI Design",
      "DevOPS",
    ],
    collaborator: ["UI DESIGN / Mukaddes Çavuşoğlu"],
    date: "11 / 2024",
    brand: "borvey",
    liveUrl: "https://www.borvey.com",
  },
  {
    title: "Bayetav Okulları",
    slug: "bayetav",
    color: "#C0D672",
    svg: BayetavSvg,
    model: "/pencil.glb",
    modelScale: 0.4,
    hoverCover: BayetavHoverCover,
    modelPosition: [0, -1, -1],
    workImages: [
      Bayetav1,
      Bayetav2,
      Bayetav3,
      Bayetav4,
      Bayetav5,
      Bayetav6,
      Bayetav7,
      Bayetav8,
      Bayetav9,
      Bayetav10,
      Bayetav11,
      Bayetav12,
      Bayetav13,
      Bayetav14,
      Bayetav15,
    ],
    subTitle: `Dünya bugün değişti. Yarın ise bambaşka olacak. Onunla birlikte yaşam tarzlarımız, çalışma biçimlerimiz, sosyal etkinliklerimiz, ilişkilerimiz de değişiyor. Hayatta bu kadar şey değişiyorken eğitime yaklaşımımız neden aynı kalsın?`,
    services: [
      "Frontend Development",
      "Backend Development",
      "UI Design",
      "DevOPS",
    ],
    date: "06 / 2024",
    brand: "Bayetav",
    liveUrl: "https://www.bayetavokullari.com",
  },
  {
    title: "Yuma Care",
    slug: "yumacare",
    color: "#272B87",
    svg: YumacareSvg,
    model: "/diamond.glb",
    modelScale: 3,
    hoverCover: YumacareHoverCover,
    modelPosition: [0, 0.5, 0],
    workImages: [
      Yumacare1,
      Yumacare2,
      Yumacare3,
      Yumacare4,
      Yumacare5,
      Yumacare6,
      Yumacare7,
      Yumacare8,
      Yumacare9,
      Yumacare10,
      Yumacare11,
      Yumacare12,
      Yumacare13,
      Yumacare14,
      Yumacare15,
      Yumacare16,
      Yumacare17,
      Yumacare18,
      Yumacare19,
    ],
    subTitle: `YUMA CARE is your platform when it comes to beauty and health.`,
    services: ["Frontend Development", "UI Design"],
    collaborator: [
      "UI DESIGN / Mukaddes Çavuşoğlu",
      "Project Management / Notussoft",
    ],
    date: "04 / 2024",
    brand: "Yuma Care / Yusuf Aslan & Maria Aslan",
    liveUrl: "https://yumacare.com",
  },
  {
    title: "Multi Creative Solutions",
    slug: "mcs",
    color: "#113CF5",
    svg: MCSSvg,
    model: "/sphere.glb",
    modelScale: 0.2,
    hoverCover: MCSHoverCover,
    modelPosition: [0, -1.5, 0],
    workImages: [
      MCS1,
      MCS2,
      MCS3,
      MCS4,
      MCS5,
      MCS6,
      MCS7,
      MCS8,
      MCS9,
      MCS10,
      MCS11,
      MCS12,
    ],
    subTitle: `Multi Creative Solutions is a creative design and production company comprised of a talent network with diverse disciplines.`,
    services: [
      "Frontend Development",
      "Backend Development",
      "Admin Panel Development",
      "DevOPS",
    ],
    collaborator: ["UI DESIGN / Ali Can Baytok"],
    date: "02 / 2024",
    brand: "Multi Creative Solutions / Ali Can Baytok",
    liveUrl: "https://www.mcs.ist",
  },
  {
    title: "Fratelli Diamanti",
    slug: "fratelli-diamanti",
    color: "#000000",
    svg: FratelliSvg,
    model: "/parfum.glb",
    modelScale: 2,
    hoverCover: FratelliDiamantiHoverCover,
    modelPosition: [0, -1.5, 0],
    workImages: [
      FratelliDiamanti1,
      FratelliDiamanti2,
      FratelliDiamanti3,
      FratelliDiamanti4,
      FratelliDiamanti5,
      FratelliDiamanti6,
      FratelliDiamanti7,
      FratelliDiamanti8,
      FratelliDiamanti9,
      FratelliDiamanti10,
      FratelliDiamanti11,
      FratelliDiamanti12,
      FratelliDiamanti13,
      FratelliDiamanti14,
    ],
    subTitle: `"Fratelli Diamanti" is much more than a perfume...`,
    services: ["Frontend Development"],
    collaborator: ["UI DESIGN / Tolga Taşçı", "BACKEND / Notussoft"],
    date: "12 / 2023",
    brand: "Fratelli Diamanti / Ceyhan Elmas",
    liveUrl: "https://fratellidiamanti.com",
  },
  {
    title: "Vialand",
    slug: "vialand",
    color: "#007BFF",
    svg: VialandSvg,
    model: "/vialand.glb",
    modelScale: 3,
    hoverCover: VialandHoverCover,
    modelPosition: [0, -2.5, 0],
    workImages: [
      Vialand1,
      Vialand2,
      Vialand3,
      Vialand4,
      Vialand5,
      Vialand6,
      Vialand7,
      Vialand8,
      Vialand9,
      Vialand10,
      Vialand11,
      Vialand12,
      Vialand13,
      Vialand14,
    ],
    subTitle: `VIALAND, the first shopping, entertainment and living complex in the world and in Turkey to combine a theme park with a shopping mall and a show center.`,
    services: [
      "Frontend Development",
      "Backend Development",
      "UI Design",
      "E-Commerce",
    ],
    date: "04 / 2023",
    brand: "Gürsoy Holding / VIALAND",
    liveUrl: "https://vialand.com",
  },

  {
    title: "Dante & Istakoz",
    slug: "dante-ve-istakoz",
    color: "#E61D26",
    svg: DanteSvg,
    model: "/lobster.glb",
    modelScale: 0.4,
    modelPosition: [0, -0.5, -1],
    hoverCover: DanteHoverCover,
    workImages: [
      Dante1,
      Dante2,
      Dante3,
      Dante4,
      Dante5,
      Dante6,
      Dante7,
      Dante8,
      Dante9,
      Dante10,
    ],
    subTitle: `Dante ve Istakoz aims to make room for publications that are interested in sides, corners and those in between.`,
    services: [
      "Frontend Development",
      "Backend Development",
      "E-Commerce",
      "DevOPS",
    ],
    date: "01 / 2023",
    brand: "Kibele Yarman",
    collaborator: ["UI DESIGN / Kibele Yarman"],
    liveUrl: "https://www.danteveistakoz.com",
  },
  {
    title: "Deste",
    slug: "deste",
    color: "#716660",
    svg: DesteSvg,
    model: "/deck.glb",
    modelPosition: [0, -4, 1.5],
    modelScale: 0.7,
    hoverCover: DesteHoverCover,
    workImages: [
      Deste1,
      Deste2,
      Deste3,
      Deste4,
      Deste5,
      Deste6,
      Deste7,
      Deste8,
      Deste9,
      Deste10,
      Deste11,
    ],
    subTitle: `With Desté, you will get 45 cards and their descriptions which will take you on a journey into your own depths.`,
    services: [
      "Frontend Development",
      "Backend Development",
      "E-Commerce",
      "DevOPS",
    ],
    date: "06 / 2022",
    brand: "Nectar Caguni / Ece Targıt Günşiray / Can Günşiray",
    collaborator: ["UI DESIGN / Naz Kayalar"],
    liveUrl: "https://www.thisisdeste.com",
  },
  {
    title: "Mural Istanbul",
    workImages: [
      MuralEast1,
      MuralEast2,
      MuralEast3,
      MuralEast4,
      MuralEast5,
      MuralEast6,
      MuralEast7,
      MuralEast8,
      MuralEast9,
      MuralEast10,
      MuralEast11,
      MuralEast12,
      MuralEast13,
    ],
    slug: "mural-east",
    color: "#4C2DC1",
    hoverCover: MuralHoverCover,
    svg: MuralSvg,
    model: "/mural.glb",
    modelScale: 1,
    modelPosition: [0, 1, 0],
    subTitle: `Mural East, the creator of street arts, mural and graffiti culture in Turkey.`,
    services: [
      "Frontend Development",
      "Backend Development",
      "Admin Panel Development",
      "DevOPS",
    ],
    date: "12 / 2022",
    brand: "Mural-East",
    collaborator: ["UI DESIGN / Informal Project / Erman Yılmaz / Gökçe Genç"],
    liveUrl: "https://mural-east.com",
  },
  {
    title: "Bubble Bots",
    workImages: [
      BubbleBots1,
      BubbleBots2,
      BubbleBots3,
      BubbleBots4,
      BubbleBots5,
      BubbleBots6,
    ],
    hoverCover: BubbleBotsHoverCover,
    color: "#422A76",
    svg: BubbleSvg,
    slug: "bubble-bots",
    model: "/robot.glb",
    modelScale: 5,
    modelPosition: [0, -4, 0],
    subTitle: `Bubble Bots, Play and Earn Nuts tokens and NFTs | Peanut Games`,
    services: ["Frontend Development"],
    date: "12 / 2022",
    brand: "Peanut Games",
    collaborator: ["UI DESIGN / Delfina Capalbo"],
    liveUrl: "https://peanutgames.com/bubblebots",
  },
  {
    title: "Peanut Games",
    workImages: [
      PeanutGames1,
      PeanutGames2,
      PeanutGames3,
      PeanutGames4,
      PeanutGames5,
      PeanutGames6,
      PeanutGames7,
      PeanutGames8,
      PeanutGames9,
    ],
    slug: "peanut-games",
    color: "#00355E",
    hoverCover: PeanutGames2,
    svg: PeanutSvg,
    model: "/gameboy.glb",
    modelScale: 30,
    modelPosition: [0, -3.7, 0],
    subTitle: `Creating the next generation of free-to-play casual mobile games`,
    services: ["Frontend Development"],
    date: "07 / 2022",
    brand: "Peanut Games",
    // collaborator: ['UI DESIGN / -ous Studio! / Caner Çokbulan'],
    liveUrl: "https://peanutgames.com",
  },
  {
    title: "S&S Med",
    workImages: [WorldofPlast1, WorldofPlast2],
    hoverCover: WorldofPlastHoverCover,
    slug: "ss-med",
    color: "#01B2AA",
    svg: SSSvg,
    model: "/pill.glb",
    modelScale: 0.05,
    modelPosition: [0, -5, 0],
    mainColor: "#01B2AA",
    subTitle: `Are you ready for new look and transformation?`,
    services: [
      "Frontend Development",
      "Backend Development",
      "Admin Panel Development",
      "DevOPS",
    ],
    date: "04 / 2022",
    brand: "S&S Med",
    collaborator: ["UI DESIGN / -ous Studio! / Caner Çokbulan"],
    liveUrl: "https://www.worldofplast.com",
  },
  {
    title: "Toolsy",
    slug: "toolsy",
    color: "#F1641F",
    svg: ToolsySvg,
    model: "/statistic.glb",
    modelScale: 3,
    modelPosition: [0, -1.5, 0],
    subTitle:
      "Analyze and Follow Your Etsy Competitors Without Wasting Your Time",
    services: [
      "Frontend Development",
      "Backend Development",
      "UI Design",
      "Project Management",
      "DevOPS",
    ],
    liveUrl: "https://toolsy.io/",
    coverImage: ToolsyCover,
    hoverCover: Toolsy2,
    workImages: [
      Toolsy1,
      Toolsy2,
      Toolsy3,
      Toolsy4,
      Toolsy5,
      Toolsy6,
      Toolsy7,
      Toolsy8,
      Toolsy9,
      Toolsy10,
      Toolsy11,
      Toolsy12,
    ],
    date: "07 / 2020",
    brand: "Toolsy",
  },
  {
    title: "Golf Always",
    coverImage: GolfAlwaysCover,
    brand: "Golf Always",
    color: "#6FB51E",
    svg: GolfSvg,
    model: "/golf.glb",
    modelScale: 6,
    modelPosition: [0, -1.5, 0],
    slug: "golf-always",
    subTitle: "Find a place to play golf.",
    services: ["UI Design"],
    hoverCover: GolfAlwaysHoverCover,
    workImages: [
      GolfAlways1,
      GolfAlways2,
      GolfAlways3,
      GolfAlways4,
      GolfAlways5,
    ],
    date: "08 / 2021",
  },
  {
    title: "Premeyoum",
    coverImage: PremeyoumCover,
    workImages: [
      Premeyoum1,
      Premeyoum2,
      Premeyoum3,
      Premeyoum4,
      Premeyoum5,
      Premeyoum6,
      Premeyoum7,
      Premeyoum8,
      Premeyoum9,
    ],
    hoverCover: PremeyoumHoverCover,
    slug: "premeyoum",
    color: "#E47D14",
    svg: PremeyoumSvg,
    model: "/guitar.glb",
    modelScale: 2.5,
    modelPosition: [0, 1, 0],
    subTitle: `Online hizmet alışkanlıklarınızı değiştirin.`,
    services: ["UI Design"],
    date: "04 / 2020",
    brand: "WeCode",
  },
  {
    title: "Kübra Çetin",
    coverImage: KubraCetinCover,
    workImages: [
      KubraCetin1,
      KubraCetin2,
      KubraCetin3,
      KubraCetin4,
      KubraCetin5,
    ],
    hoverCover: KubraCetin2,
    slug: "kubra-cetin",
    color: "#6F5650",
    svg: KubraSvg,
    model: "/mirror.glb",
    modelScale: 6,
    modelPosition: [0, -3.5, 0],
    subTitle: `Kübra Çetin portfolio website.`,
    services: ["Frontend Development"],
    date: "05 / 2021",
    brand: "Kübra Çetin",
    liveUrl: "https://kubra-cetin-portfolio-v2.vercel.app",
    collaborator: ["UI DESIGN / Kübra Çetin"],
  },
  {
    title: "-ous Studio",
    coverImage: OusStudiCover,
    workImages: [
      OusStudio1,
      OusStudio2,
      OusStudio3,
      OusStudio4,
      OusStudio5,
      OusStudio6,
      OusStudio7,
      OusStudio8,
      OusStudio9,
    ],
    hoverCover: OusStudio2,
    slug: "ous-studio",
    color: "#000000",
    svg: OusSvg,
    model: "/mac.glb",
    modelScale: 25,
    modelPosition: [0, -3.5, 0],
    subTitle: `Atelier / Design Studio based in İstanbul.`,
    services: ["Frontend Development"],
    date: "08 / 2019",
    liveUrl: "https://ousstudio.com",
    brand: "-ous Studio",
    collaborator: ["Caner Çokbulan", "Gizem Çokbulan"],
  },
  {
    title: "Safe Kids",
    coverImage: SafeKidsCover,
    workImages: [
      SafeKids1,
      SafeKids2,
      SafeKids3,
      SafeKids4,
      SafeKids5,
      SafeKids6,
      SafeKids7,
    ],
    hoverCover: SafeKids2,
    slug: "safe-kids",
    color: "#F25041",
    svg: SafeKidsSvg,
    model: "/tv.glb",
    modelScale: 3,
    modelPosition: [0, -6.5, 0],
    subTitle: `Fun TV for Kids.`,
    services: ["UI Design"],
    date: "10 / 2020",
    brand: "WeCode",
  },
  {
    title: "Aremas",
    coverImage: AremasCover,
    workImages: [Aremas1, Aremas2, Aremas3, Aremas4, Aremas5, Aremas6],
    hoverCover: AremasHoverCover,
    slug: "aremas",
    color: "#333E48",
    svg: AremasSvg,
    model: "/building.glb",
    modelScale: 4,
    modelPosition: [0, -1.5, 0],
    subTitle: `One Stop Consultancy`,
    services: ["UI Design", "Frontend Development", "Admin Panel Development"],
    date: "10 / 2021",
    brand: "Aremas",
    liveUrl: "http://demo.aremas.net",
  },
  {
    title: "Move On",
    coverImage: MoveOnCover,
    workImages: [MoveOn1, MoveOn2, MoveOn3, MoveOn4],
    hoverCover: MoveOnHoverCover,
    slug: "move-on",
    color: "#81AA0E",
    svg: MoveOnSvg,
    model: "/sneakers.glb",
    modelScale: 40,
    modelPosition: [0, -1.5, 0],
    subTitle: `Track your movement.`,
    services: ["UI Design"],
    date: "11 / 2021",
    brand: "WeCode",
  },
];
