import LogoBlack from '../../assets/img/logo_black.png';
import LogoWhite from '../../assets/img/logo_white.png';
import style from './header.module.scss';

const Header = ({ white }) => {
  return (
    <header className={`${style.header} ${white ? style.white : null}`}>
      <a href="/" className={style.logo}>
        <img src={white ? LogoWhite : LogoBlack} />
      </a>
      <ul>
        <li>
          <a href="/studio"> studio</a>
        </li>
        <li className={style.products}>
          <a> products</a>
        </li>
        <li>
          <a href="/works"> works</a>
        </li>
        <li>
          <a href="/contact"> contact</a>
        </li>
      </ul>
    </header>
  );
};

export default Header;
