import style from './studio.module.scss';
import CircleLogoBlack from '../../assets/img/logo/circle-logo-black.png';
import { PlusIcon } from '../../assets/icon';
import { Header, Footer, MainHelmet } from '../../components';
import { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import JavascriptLogo from '../../assets/img/technologies/javascript.png';
import ReactLogo from '../../assets/img/technologies/react.png';
import ReactNativeLogo from '../../assets/img/technologies/react-native.png';
import NodeLogo from '../../assets/img/technologies/nodejs.png';
import MongoLogo from '../../assets/img/technologies/mongodb.png';
import FirebaseLogo from '../../assets/img/technologies/firebase.png';
import AirtableLogo from '../../assets/img/technologies/airtable.png';
import PostgreSqlLogo from '../../assets/img/technologies/postgre-sql.png';
import DigitalOceanLogo from '../../assets/img/technologies/digital-ocean.png';
import VercelLogo from '../../assets/img/technologies/vercel.png';
import HerokuLogo from '../../assets/img/technologies/heroku.png';
import IyzicoLogo from '../../assets/img/technologies/iyzico.png';
import ExpressLogo from '../../assets/img/technologies/express.png';
import WebglLogo from '../../assets/img/technologies/webgl.png';
import NextLogo from '../../assets/img/technologies/next.png';
import ReduxLogo from '../../assets/img/technologies/redux.png';

const technologiesData = [
  {
    name: 'Javascript',
    logo: JavascriptLogo,
  },
  {
    name: 'React JS',
    logo: ReactLogo,
  },
  {
    name: 'React Native',
    logo: ReactNativeLogo,
  },
  {
    name: 'Node JS',
    logo: NodeLogo,
  },
  {
    name: 'Mongo DB',
    logo: MongoLogo,
  },
  {
    name: 'Firebase',
    logo: FirebaseLogo,
  },
  {
    name: 'Airtable',
    logo: AirtableLogo,
  },
  {
    name: 'Postgre SQL',
    logo: PostgreSqlLogo,
  },
  {
    name: 'Digital Ocean',
    logo: DigitalOceanLogo,
  },
  {
    name: 'Vercel',
    logo: VercelLogo,
  },
  {
    name: 'Heroku',
    logo: HerokuLogo,
  },
  {
    name: 'Iyzico',
    logo: IyzicoLogo,
  },
  {
    name: 'Express JS',
    logo: ExpressLogo,
  },
  {
    name: 'WebGL',
    logo: WebglLogo,
  },
  {
    name: 'Next JS',
    logo: NextLogo,
  },
  {
    name: 'Redux',
    logo: ReduxLogo,
  },
];

const Studio = () => {
  const [isOpen, setOpen] = useState([]);

  const openAction = value => {
    if (isOpen.includes(value)) {
      setOpen(isOpen.filter(i => i !== value));
    } else {
      setOpen([...isOpen, value]);
    }
  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const styles = useSpring({
    left: isMenuOpen ? '-60%' : '0%',
    top: isMenuOpen ? '15vh' : '0vh',
    borderRadius: isMenuOpen ? '20px' : '0',
    boxShadow: '10px -10px 60px rgba(0, 0, 0, 0.3)',
    config: { duration: 300 },
  });

  return (
    <>
      <MainHelmet
        title={'Studio'}
        slug={'/studio'}
        keywords={
          'alter-text, Studio, frontend,backend, development, ui, design'
        }
        description={
          'Alter-Text is an independent web studio founded in 2021. We offer creative and systemic solutions to create digital products. We love using new generation technologies.'
        }
      />
      <Header />
      <animated.div style={{ ...styles }} className={style.studio}>
        <div className={style.wrapper}>
          <div className={style.head}>
            <div className={style.row}>
              <h1>Independent Web Studio</h1>
              <img src={CircleLogoBlack} alt="logo-circle-black" />
            </div>
            <div className={style.row}>
              <h1>
                from <span>Istanbul</span>
              </h1>
            </div>
          </div>
          <div className={style.info}>
            <div className={style.row}>
              <p>
                Alter-Text is an independent web studio founded in 2021. We
                offer creative and systemic solutions to create digital
                products. We love using new generation technologies.
              </p>
            </div>
            <div className={style.row}>
              <h3>
                creative
                <br /> stuff is fun.
              </h3>
              <p>
                Creativity is our top priority. We love working with creative
                and unique stuff. Like WebGL technology and Design thinking, we
                can produce great results.
              </p>
            </div>
          </div>
          <div className={style.services}>
            <h5>services</h5>
            <div className={style.servicesWrapper}>
              <div className={style.serviceRow}>
                <div
                  onClick={() => openAction('development')}
                  className={`${style.head} ${
                    isOpen.includes('development') ? style.isOpen : null
                  }`}
                >
                  <h6>Development</h6>
                  <PlusIcon />
                </div>
                {isOpen.includes('development') ? (
                  <div className={style.content}>
                    <ul>
                      <li>Frontend Development</li>
                      <li>Mobile App Development</li>
                      <li>Backend Development</li>
                      <li>DevOps</li>
                      <li>Database Management</li>
                    </ul>
                    <p>
                      Developing is our primary service. During the development
                      process, we take care to choose the latest and most
                      sustainable technologies.
                    </p>
                  </div>
                ) : null}
              </div>
              <div className={style.serviceRow}>
                <div
                  onClick={() => openAction('design')}
                  className={`${style.head} ${
                    isOpen.includes('design') ? style.isOpen : null
                  }`}
                >
                  <h6>Design</h6>
                  <PlusIcon />
                </div>
                {isOpen.includes('design') ? (
                  <div className={style.content}>
                    <ul>
                      <li>UI Design</li>
                      <li>UX Design</li>
                      <li>Mobile App</li>
                      <li>Website</li>
                      <li>Creative Direction</li>
                    </ul>
                    <p>
                      We specialize in turning ideas into a digital products,
                      and produce designs that solve the problems of these ideas
                      in the most efficient way possible.
                    </p>
                  </div>
                ) : null}
              </div>

              <div className={style.serviceRow}>
                <div
                  onClick={() => openAction('blockchain')}
                  className={`${style.head} ${
                    isOpen.includes('blockchain') ? style.isOpen : null
                  }`}
                >
                  <h6>Project Management</h6>
                  <PlusIcon />
                </div>
                {isOpen.includes('blockchain') ? (
                  <div className={style.content}>
                    <ul>
                      <li>SAAS Products</li>
                      <li>E-Commerce</li>
                      <li>Mobile App</li>
                      <li>Services</li>
                      <li>Portfolio</li>
                    </ul>
                    <p>
                      Good ideas always need a project and that project needs a
                      plan.
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={style.technologies}>
            <h5>technologies</h5>
            <div className={style.technologiesWrapper}>
              {technologiesData.map(item => {
                return (
                  <div className={style.image}>
                    <img src={item.logo} alt={item.name} />
                    <div className={style.cover} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={`${style.info} ${style.bottomInfo}`}>
            <div className={style.row}>
              <h3>
                <span>soon</span>
                we’re gonna
                <br /> create <br /> products
              </h3>
              <p>
                We plan on building our own products in the near future. Will be
                sharing their announcement and details in the near future.
              </p>
            </div>
          </div>
        </div>
      </animated.div>
    </>
  );
};

export default Studio;
