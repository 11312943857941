import { HomePageSvg, HomePageSvgMobile } from '../../assets/icon';
import style from './home.module.scss';
import { Aquarium, Header, Loading, CanRotate } from '../../components';
import useWindowDimensions from '../../helpers/windowWidth';
import { useEffect, useState } from 'react';

const Home = () => {
  const { width } = useWindowDimensions();
  const [isLoading, setLoading] = useState(true);
  const [canRotate, setRotate] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        localStorage.setItem('canRotate', 'true');
        setRotate(false);
      }, 3000);
    }
  }, [isLoading]);

  useEffect(() => {
    const rotateState = localStorage.getItem('canRotate');

    if (rotateState) {
      setRotate(false);
    } else {
      setRotate(true);
    }
  }, []);
  return (
    <>
      {isLoading ? <Loading /> : null}
      {!isLoading && canRotate ? (
        <CanRotate
          click={() => {
            localStorage.setItem('canRotate', 'true');
            setRotate(false);
          }}
        />
      ) : null}

      <div className={style.home}>
        <Header white={width > 940} />
        {width > 940 ? <HomePageSvg /> : <HomePageSvgMobile />}
        <div className={style.scene}>
          <Aquarium
            scale={23}
            spheres={[[1, 'transparent', 0.05, [-4, -1, -1]]]}
            ready={() => setLoading(false)}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
