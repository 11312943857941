import { RotateIcon } from '../../assets/icon';
import style from './canrotate.module.scss';

const CanRotate = ({ click }) => {
  return (
    <div onClick={click} className={style.rotate}>
      <div>
        <RotateIcon />
        <span>YOU CAN ROTATE THE CUBE!</span>
      </div>
    </div>
  );
};

export default CanRotate;
