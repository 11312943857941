import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/style/variables.module.scss';
import { Suspense } from 'react';
import { Loading } from './components';

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
