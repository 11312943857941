import { Header, MainHelmet } from '../../components';
import { WorkData } from '../../data/WorkData';
import style from './works.module.scss';
import { useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import useWindowDimensions from '../../helpers/windowWidth';

const Works = () => {
  const [selectedWork, setSelectedWork] = useState();
  const { width } = useWindowDimensions();
  return (
    <div
      style={{
        backgroundColor: selectedWork ? selectedWork.color : 'black',
      }}
      className={style.works}
    >
      <MainHelmet
        title={'Works'}
        slug={'/works'}
        keywords={
          'alter-text, Studio, frontend,backend, development, ui, design'
        }
        description={
          'Alter-Text is an independent web studio founded in 2021. We offer creative and systemic solutions to create digital products. We love using new generation technologies.'
        }
      />
      <Header />
      {width < 940 ? <h1>Works</h1> : null}
      {width > 940 ? (
        <div className={style.workList}>
          {WorkData.map(item => {
            return (
              <a
                onMouseEnter={() => setSelectedWork(item)}
                onMouseLeave={() => setSelectedWork()}
                href={`/work/${item.slug}`}
                className={
                  selectedWork && selectedWork.slug === item.slug
                    ? style.active
                    : selectedWork
                    ? style.passive
                    : null
                }
              >
                {selectedWork && selectedWork.slug === item.slug ? (
                  <div
                    style={{
                      background: selectedWork.color,
                    }}
                    className={style.card}
                  >
                    <div className={style.imageList}>
                      <img src={selectedWork.hoverCover} />
                    </div>
                  </div>
                ) : null}
                {<item.svg />}
              </a>
            );
          })}
        </div>
      ) : (
        <Controller>
          <div className={style.workList}>
            <>
              {WorkData.map(item => {
                return (
                  <Scene
                    duration={200}
                    offset={0}
                    triggerElement={`#${item.slug}`}
                  >
                    {progress => {
                      console.log('progress', progress);
                      if (progress > 0 && progress < 1) {
                        console.log(item.title);
                        setSelectedWork(item);
                      }
                      return (
                        <a
                          href={`/work/${item.slug}`}
                          className={
                            selectedWork && selectedWork.slug === item.slug
                              ? style.active
                              : selectedWork
                              ? style.passive
                              : null
                          }
                          id={item.slug}
                        >
                          {selectedWork && selectedWork.slug === item.slug ? (
                            <div
                              style={{
                                background: selectedWork.color,
                              }}
                              className={style.card}
                            >
                              <div className={style.imageList}>
                                <img src={selectedWork.hoverCover} />
                              </div>
                            </div>
                          ) : null}
                          {<item.svg />}
                        </a>
                      );
                    }}
                  </Scene>
                );
              })}
            </>
          </div>
        </Controller>
      )}
    </div>
  );
};
export default Works;
