import { Home, WorkDetail, Works, Contact, Studio } from '../containers';

export const routes = [
  {
    title: 'Home',
    path: '*',
    component: <Home route={''} />,
    protected: false,
  },
  {
    title: 'Workdetail',
    path: '/work/:slug',
    component: <WorkDetail route={''} />,
    protected: false,
  },
  {
    title: 'Works',
    path: '/works',
    component: <Works route={''} />,
    protected: false,
  },
  {
    title: 'Studio',
    path: '/studio',
    component: <Studio route={''} />,
    protected: false,
  },
  {
    title: 'Contact',
    path: '/contact',
    component: <Contact route={''} />,
    protected: false,
  },
];
